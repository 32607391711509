import React from "react"
import ContactUs from "../components/common/contactUs"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import FAQContent from "../components/faq/faqContent"

const title = `Häufige Fragen`

const FAQPage = () => (
  <Layout title={title} >
    <SEO title={title} pathname="/faq" />
    <FAQContent />
    <ContactUs />
  </Layout>
)

export default FAQPage