import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import rehypeReact from "rehype-react"
import config from "../../../data/SiteConfig"
import ResponsiveImage from "../common/responsiveImage"
import ResponsiveImageText from "../common/responsiveImageText"
import Teddy from "../images/teddy"
import TeddySquare from "../images/teddySquare"
import FAQPair from "./faqPair"

export default function FAQContent() {
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      "faq-pair": FAQPair
    }
  }).Compiler
  
  const data = useStaticQuery(graphql`
    query {
      intro: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(faq/1_intro.md)/" }
        }
      ) {
        edges {
          node {
            html
          }
        }
      }
      questions: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(faq/2_questions.md)/" }
        }
      ) {
        edges {
          node {
            htmlAst
          }
        }
      }
      examples: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(faq/3_examples.md)/" }
        }
      ) {
        edges {
          node {
            htmlAst
          }
        }
      }
    }
  `)

  return (
    <div>
      <ResponsiveImageText
        image={
          <ResponsiveImage
            portrait={<Teddy className={`stretchWide`} />}
            landscape={<TeddySquare />}
            breakpoint={config.layoutBreakpointDesktop}
          />
        }
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data.intro.edges[0].node.html,
          }}
        />
      </ResponsiveImageText>
      <div id={`faq-questions`}>
        { renderAst(data.questions.edges[0].node.htmlAst) }
      </div>
      <div id={`faq-examples`}>
        { renderAst(data.examples.edges[0].node.htmlAst) }
      </div>
    </div>
  )
}
