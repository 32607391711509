import React from "react"
import styles from "./faqPair.module.css"

export default class FAQPair extends React.Component {
  constructor(props) {
    super(props);
    this.question = props.question;
    this.children = props.children;
    this.state = {
      hidden: true
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState({
      hidden: !this.state.hidden
    });
  }

  render() {
    return (
      <div className={`${styles.wrapper} ${!this.state.hidden ? styles.line : ``}`}>
        <div className={styles.questionLine}>
          <h5 onClick={this.handleClick}>{this.question}</h5>
        </div>
        <div className={`${styles.answer} ${this.state.hidden ? styles.hidden : styles.shown}`}>
          {this.children}
        </div>
      </div>
    )
  }
}